import { CasinoGameTag, TranslationScopes } from "@finbackoffice/enums";
import { formatAppNameText, CasinoGameTypeEnum } from "@finbackoffice/fe-core";
import { RuntimeConfigContext, useRuntimeConfig, useTranslation } from "@finbackoffice/site-core";
import { FC, useContext, useMemo } from "react";
import classNames from "classnames";
import Button from "components/base/button/Button";
import Img from "components/base/img/Img";
import { Svg } from "components/base/svg/Svg";
import Translate from "components/base/translate/Translate";
import Video from "components/base/video/Video";
import { getCasinoGameImgSrc } from "utils/helpers";
import { CasinoGameItemContext } from "../../CasinoGameItem";
import styles from "./compact.module.sass";

type IProps = {
    handlePlayNow: () => void;
    handlePlayForFun?: () => void;
    wrapperClassname?: string;
};

export const CasinoGamePlayButtons: FC<IProps> = ({
    handlePlayNow,
    handlePlayForFun,
    wrapperClassname,
}) => (
    <div className={classNames(styles.btnsContainer, wrapperClassname && wrapperClassname)}>
        <Button type="button" className={styles.playNowBtn} onClick={handlePlayNow}>
            <Translate tid="casino_slots_play_now" namespace={TranslationScopes.CasinoSlots} />
        </Button>
        {!!handlePlayForFun && (
            <Button type="button" variant="thirdary" onClick={handlePlayForFun}>
                <Translate tid="casino_slots_practice" namespace={TranslationScopes.CasinoSlots} />
            </Button>
        )}
    </div>
);

const Compact: FC = () => {
    const runtimeConfig = useContext(RuntimeConfigContext);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { t } = useTranslation(TranslationScopes.Casino);
    const {
        gameId,
        game,
        disableLocalThumbnail,
        handlePlayNow,
        gameName,
        isUserLoggedIn,
        isFavorite,
        handleFavoriteClick,
        handlePlayForFun,
        showName,
        fallbackSrc,
        hidePlayButtons,
        tagsMemo,
        isFallbackSrcActive,
        onFallbackHandler,
        imgSrc,
        imgFolder,
    } = useContext(CasinoGameItemContext);

    const gameImgSrc = useMemo(() => {
        if (imgSrc && game[imgSrc]) {
            return game[imgSrc];
        } else {
            return disableLocalThumbnail
                ? game.image_filled
                : getCasinoGameImgSrc(
                      runtimeConfig,
                      gameId,
                      game.type as CasinoGameTypeEnum,
                      game.tags,
                      imgFolder ? { folder: imgFolder } : undefined,
                  );
        }
    }, [disableLocalThumbnail, game, gameId, imgSrc, runtimeConfig, imgFolder]);

    const fallbackSrcMemo = useMemo(() => {
        const formatedAppName = formatAppNameText(COMMON_SITE_CONFIGS.appName);
        const hasAppNameInSrc = gameImgSrc.includes(formatedAppName);
        if (isFallbackSrcActive && hasAppNameInSrc) {
            return fallbackSrc;
        }
        return hasAppNameInSrc ? gameImgSrc.replace(`_${formatedAppName}`, "") : fallbackSrc;
    }, [COMMON_SITE_CONFIGS.appName, fallbackSrc, gameImgSrc, isFallbackSrcActive]);

    return (
        <div data-testid={gameId} className={classNames("casino-game-item", styles.gameItem)}>
            {Boolean(game.tags?.length) && (
                <span className={styles.gameLabel}>
                    {tagsMemo.map((tag: CasinoGameTag) => (
                        <Svg
                            key={tag}
                            src={`/common/casino/compact/icon-casino-${tag.toLowerCase()}.svg`}
                            wrapper="span"
                            className={classNames(
                                "svg-icon",
                                styles[`icon-casino-${tag.toLowerCase()}`],
                            )}
                        />
                    ))}
                </span>
            )}
            <div
                onClick={hidePlayButtons ? handlePlayNow : undefined}
                className={classNames(hidePlayButtons && styles.playBtn)}>
                {game.tags.includes(CasinoGameTag.DropsAndWins) && (
                    <Img
                        source={`${ASSETS_URL}/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/common/icon-casino-drops-and-wins.svg`}
                        alt={CasinoGameTag.DropsAndWins.toLowerCase()}
                        width={60}
                        height={60}
                        wrapperClassName="casino-drops-wins"
                    />
                )}
                {gameImgSrc.includes("mp4") ? (
                    <Video src={gameImgSrc} />
                ) : (
                    <Img
                        source={gameImgSrc}
                        alt={`${t(`casino_${game.type.replaceAll("-", "_")}`)} ${gameName}`}
                        title={`${t(`casino_${game.type.replaceAll("-", "_")}`)} ${gameName}`}
                        wrapperClassName={classNames(
                            styles.gameItemImg,
                            isFallbackSrcActive && "game-item-img-fallback",
                        )}
                        onFallback={onFallbackHandler}
                        width={0}
                        height={0}
                        style={{ width: "100%", height: "auto" }}
                        fallbackSrc={fallbackSrcMemo || game.image_portrait}
                    />
                )}

                {!hidePlayButtons && (
                    <CasinoGamePlayButtons
                        handlePlayNow={handlePlayNow}
                        handlePlayForFun={
                            game.play_for_fun_supported ? handlePlayForFun : undefined
                        }
                    />
                )}
            </div>
            <section className={styles.gameItemName}>
                {showName && <span title={game.name}>{game.name}</span>}
                {game.category}
                {isUserLoggedIn && (
                    <i
                        className={classNames(styles.unstarred, isFavorite && styles.starred)}
                        onClick={handleFavoriteClick}
                    />
                )}
            </section>
        </div>
    );
};

export default Compact;
